import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import AudienceInterest from './AudienceInterest'; // Import AudienceInterest component

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  margin: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme?.palette?.grey?.[theme?.palette?.mode === 'dark' ? 800 : 200] ||
      '#f0f0f0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme?.palette?.mode === 'dark' ? '#308fe8' : '#7c087f',
  },
}));

const BrandAffinityInterest = ({
  audienceBrands = {},
  audienceInterests = {},
}) => {
  const [brandAffinity, setBrandAffinity] = useState({});

  useEffect(() => {
    if (audienceBrands && typeof audienceBrands === 'object') {
      setBrandAffinity(audienceBrands);
    } else {
      setBrandAffinity({});
      console.error(
        'Invalid data passed to BrandAffinityInterest:',
        audienceBrands
      );
    }
  }, [audienceBrands]);

  if (!brandAffinity || Object.keys(brandAffinity).length === 0) {
    return (
      <Typography sx={{ pt: 4, textAlign: 'center', fontWeight: 'bold' }}>
        No brand affinity data available.
      </Typography>
    );
  }

  return (
    <Grid container spacing={2} sx={{ pt: 4 }}>
      {/* Left Grid Item for Brand Affinity */}
      <Grid item xs={12} md={6} sx={{ padding: '10px' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Audience Brand Affinity
        </Typography>

        <Stack
          direction="column"
          spacing={2}
          sx={{ padding: '10px', height: '300px', overflowY: 'auto' }}
        >
          {Object.entries(brandAffinity).map(([brand, affinityValue]) => {
            const progress =
              typeof affinityValue === 'number' ? affinityValue / 100 : 0;
            return (
              <Stack direction="column" spacing={2} key={brand}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {brand}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', paddingRight: '10px' }}
                  >
                    {progress.toFixed(0)}%
                  </Typography>
                </Stack>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Stack>
            );
          })}
        </Stack>
      </Grid>

      {/* Right Grid Item for Audience Interests */}
      <AudienceInterest audienceInterests={audienceInterests} />
    </Grid>
  );
};

export default BrandAffinityInterest;
