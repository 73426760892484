import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { Element } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Card, Paper, Stack, Typography, Chip, Button } from '@mui/material';
import {
  getFollowersGrowth,
  getInfluencerDetails,
  getInfluencerRank,
} from 'api/brand/search';
import {
  AudienceSection,
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfProfileDetailsNavbar,
  OverviewCard,
  GrowthChart,
  BrandAffinityInterest,
  FollowerType,
} from 'components/common/inf-profile';
import { formatNumber } from 'utils';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  instaPost,
  instaReels,
  platformIcon,
  usersGrayIcon,
} from 'constants/images';
import useFormatInstaAgeGenderData from 'hooks/useFormatInstaAgeGenderData';
import useFromatInstaCountryData from 'hooks/useFromatInstaCountryData';
import useFormatInstaCityData from 'hooks/useFormatInstaCityData';
import { NoData } from 'components/common/NoData';

const InstagramProfile = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { id: influencerId } = useParams();
  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['brand-influencer-rank', influencerId],
    queryFn: () => getInfluencerRank({ influencerId }),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: [
        'brand-influencer-follower-growth',
        influencerId,
        'instagram',
        follersGrowthFilter,
      ],
      queryFn: () => {
        const params = {
          influencer_id: influencerId,
          follower_growth_filter: follersGrowthFilter,
          platform: 'instagram',
        };
        return getFollowersGrowth(params);
      },
    });

  const { instagramProfile } = influencer || {};
  const {
    postsStats,
    videoStats,
    reelsStats,
    brandPosts,
    recentPosts,
    topPosts,
    hashtags,
    brands,
    audienceByAgeGroupAndGenderRaw,
    audienceByCountryRaw,
    audienceByCityRaw,
    creatorPerformanceRaw,
    growthDataRaw,
    audienceTypesRaw,
    audienceBrandsRaw,
    audienceInterestsRaw,
    audienceLanguagesRaw,
    audienceEthnicitiesRaw,
    formattedCreatorInterests,
    frequentlyTaggedAccounts = [],
  } = instagramProfile || {};
  console.log(formattedCreatorInterests);

  const { audienceByAgeGroup, audienceByGender } = useFormatInstaAgeGenderData(
    audienceByAgeGroupAndGenderRaw
  );
  const audienceByCountry = useFromatInstaCountryData(audienceByCountryRaw);
  const { audienceByCity } = useFormatInstaCityData(audienceByCityRaw);
  const [showAllTaggedAccounts, setShowAllTaggedAccounts] = useState(false);
  const toggleShowAllTaggedAccounts = () => {
    setShowAllTaggedAccounts((prev) => !prev);
  };
  const taggedAccountsToShow = useMemo(
    () =>
      showAllTaggedAccounts
        ? frequentlyTaggedAccounts
        : frequentlyTaggedAccounts?.slice(0, 30),
    [frequentlyTaggedAccounts, showAllTaggedAccounts]
  );
  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />
      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Followers"
          value={formatNumber(instagramProfile?.followers)}
        />
        <OverviewCard
          title="Total Posts"
          value={formatNumber(instagramProfile?.totalMedia)}
        />
        {/* <OverviewCard
          title="Estimated reach"
          value={formatNumber(followerGrowthData?.estimatedReach)}
        /> */}
        <OverviewCard
          title="Engagement Rate"
          value={formatNumber(instagramProfile?.avgEngagementRate)}
        />
        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(instagramProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Average Reels Views"
          value={formatNumber(
            instagramProfile?.creatorPerformanceRaw?.[0]?.average_reels_views
          )}
        />
        <OverviewCard
          title="Average likes per post"
          value={formatNumber(
            instagramProfile?.creatorPerformanceRaw?.[0]?.average_likes
          )}
        />
        <OverviewCard
          title="Average comments per post"
          value={formatNumber(
            instagramProfile?.creatorPerformanceRaw?.[0]?.average_comments
          )}
        />
      </Element>
      <Element
        className={`${styles.navCategory} ${styles.engagementSectionPadding}`}
        name="engagement"
      >
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={instaPost}
            title="Posts"
            items={[
              {
                name: 'Avg. Likes',
                value: formatNumber(postsStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(postsStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(postsStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
              {
                name: 'Engagement Rate',
                value: `${formatNumber(instagramProfile?.avgEngagementRate)}%`,
                info: 'engagement_rate = (total_likes+total_comments)/total_media',
              },
            ]}
          />
          {/* <EngagementCard
            icon={instaVideos}
            title="Videos"
            items={[
              {
                name: 'Avg. Likes',
                value: formatNumber(videoStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(videoStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(videoStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          /> */}
          <EngagementCard
            icon={instaReels}
            title="Reels"
            items={[
              {
                name: 'Avg. Likes',
                value: formatNumber(reelsStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(reelsStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(reelsStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
        </div>
      </Element>
      {/* Growth Charts */}
      {instagramProfile?.growthDataRaw &&
        instagramProfile?.growthDataRaw.length > 0 && (
          <Stack
            name="growth"
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ width: '100%' }}
          >
            {instagramProfile?.growthDataRaw.some(
              (item) => item.followers > 0
            ) && (
              <GrowthChart
                title="Followers Growth"
                subtitle="Monthly Followers Trend"
                data={instagramProfile?.growthDataRaw}
                xKey="date"
                yKey="followers"
                color="#7C087F"
              />
            )}
            {instagramProfile?.growthDataRaw.some((item) => item.likes > 0) && (
              <GrowthChart
                title="Likes Growth"
                subtitle="Monthly Like Trend Per Post"
                data={instagramProfile?.growthDataRaw}
                xKey="date"
                yKey="likes"
                color="#7C087F"
              />
            )}
          </Stack>
        )}
      <AudienceSection
        audienceByCity={audienceByCity}
        audienceByCountry={audienceByCountry}
        audienceByGender={audienceByGender}
        audienceByAgeGroup={audienceByAgeGroup}
      />
      {/* <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element> */}
      <Element name="content">
        <ContentSection
          brandPosts={brandPosts}
          recentPosts={recentPosts}
          topPosts={topPosts}
          hashtags={hashtags}
        />
      </Element>

      {instagramProfile?.audienceBrandsRaw &&
        Object.keys(instagramProfile?.audienceBrandsRaw || {}).length > 0 &&
        instagramProfile?.audienceInterestsRaw &&
        Object.keys(instagramProfile?.audienceInterestsRaw || {}).length >
          0 && (
          <BrandAffinityInterest
            audienceBrands={instagramProfile?.audienceInterestsRaw}
            audienceInterests={instagramProfile?.audienceInterestsRaw}
          />
        )}

      {instagramProfile?.audienceTypesRaw?.real > 0 && (
        <FollowerType audienceTypes={instagramProfile?.audienceTypesRaw} />
      )}

      {instagramProfile?.brands && instagramProfile?.brands.length > 0 && (
        <>
          <h2 className={styles.contntTxt}>Creators Brands Affinity</h2>
          <Stack direction="row" gap={2} flexWrap="wrap">
            {instagramProfile?.brands.map(({ name, logo }) => (
              <Paper
                key={name}
                variant="outlined"
                sx={{ p: 2, bgcolor: 'background.neutral' }}
              >
                {name}
              </Paper>
            ))}
          </Stack>
        </>
      )}

      {instagramProfile?.formattedCreatorInterests &&
        Object.keys(instagramProfile?.formattedCreatorInterests).length > 0 && (
          <Stack sx={{ pt: 4 }}>
            <Typography variant="h6">Creators Interests</Typography>
            <Stack direction="row" gap={2} flexWrap="wrap">
              {Object.keys(instagramProfile?.formattedCreatorInterests).map(
                (interest) => (
                  <Paper
                    key={interest}
                    variant="outlined"
                    sx={{ p: 2, bgcolor: 'background.neutral' }}
                  >
                    {interest}
                  </Paper>
                )
              )}
            </Stack>
          </Stack>
        )}

      {instagramProfile?.frequentlyTaggedAccounts &&
        instagramProfile?.frequentlyTaggedAccounts.length > 0 && (
          <Stack sx={{ pt: 4 }}>
            <Typography variant="h6">Frequently Tagged Accounts</Typography>
            <Stack direction="row" gap={1} flexWrap="wrap" my={1}>
              {taggedAccountsToShow.map((account, index) => (
                <Chip
                  label={account}
                  key={index}
                  size="small"
                  sx={{ borderRadius: 0.5 }}
                />
              ))}
              {instagramProfile?.frequentlyTaggedAccounts.length > 30 && (
                <Button
                  variant="text"
                  size="small"
                  onClick={toggleShowAllTaggedAccounts}
                >
                  {showAllTaggedAccounts ? 'View Less' : 'View All'}
                </Button>
              )}
            </Stack>
          </Stack>
        )}

      {/* <Element name="brands">
        <BrandsSection
          brands={brands}
          platform="instagram"
          variant="brand"
          influencerId={influencerId}
        />
      </Element> */}

      {!instagramProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default InstagramProfile;
