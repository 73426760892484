import React from 'react';
import { Slider as MuiSlider, sliderClasses } from '@mui/base/Slider';

const css = `
    .base-slider{
        color: #FF00A2;
        height: 6px;
        width: 100%;
        padding: 16px 0;
        display: inline-block;
        position: relative;
        cursor: pointer;
        touch-action: none;
        -webkit-tap-highlight-color: transparent;
    }
    .base-slider:hover {
        opacity: 1;
    }

    .base-slider.${sliderClasses.disabled} { 
        pointer-events: none;
        cursor: default;
        color: #757575;
        opacity: 0.5;
    }

    .base-slider .${sliderClasses.rail} {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #ff00a299;
    }
    
    .base-slider .${sliderClasses.track} {
        display: block;
        position: absolute;
        height: 2px;
        border-radius: 2px;
        background-color: currentColor;
    }

    .base-slider .${sliderClasses.thumb} {
        position: absolute;
        width: 16px;
        height: 16px;
        margin-left: -6px;
        margin-top: -6px;
        box-sizing: border-box;
        border-radius: 50%;
        outline: 0;
        border: 3px solid currentColor;
        background-color: #FF00A2;
    }
    .base-slider .${sliderClasses.thumb}:hover,
    .base-slider .${sliderClasses.thumb}.${sliderClasses.focusVisible} {
        box-shadow: 0 0 0 0.25rem #ff00a247;
    }
    .base-slider .${sliderClasses.thumb}.${sliderClasses.active} {
        box-shadow: 0 0 0 0.25rem #ff00a22e;
    }
    
    .base-slider .${sliderClasses.mark} {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: currentColor;
        top: 50%;
        opacity: 0.7;
        transform: translateX(-50%);
    }

    .base-slider .${sliderClasses.markActive} {
        background-color: #fff;
    }
`;

const Slider = ({ ...sliderProps }) => (
  <div>
    <style>{css}</style>
    <MuiSlider {...sliderProps} className="base-slider" />
  </div>
);

export default Slider;
