import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProfile, getMessages, sendIGMessage } from 'api/influencer/profile';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';
import ChatWindow from './Chat';

const Messenger = () => {
  const [activeConversation, setActiveConversation] = React.useState(null);
  const [refetchIntervalValue, setRefetchIntervalValue] = React.useState(10000);

  // Handler for when a conversation is clicked
  const handleConversationClick = (conversation) => {
    setActiveConversation(conversation.id);
  };

  // Handler to close the chat window
  const handleCloseChat = () => {
    setActiveConversation(false);
  };

  const sendMessage = (event) => {
    if (event?.type === 'submit') {
      event.preventDefault();
      const inputField = document.getElementById('messageInput');
      const message = inputField.value;
      const toID = queryData.data
        .filter((chat) => chat.id === activeConversation)[0]
        .participants.data.map((participant) => participant.id)
        .filter((participant) => participant !== igDataObj.instagram_id)[0];
      const body = {
        ig_access_token: igDataObj.access_token,
        ig_user_id: igDataObj.instagram_id,
        messageData: {
          recipient: {
            id: toID,
          },
          message: {
            text: message,
          },
        },
      };
      sendIGMessage(body);
      // clear input field
      inputField.value = '';
    }
  };

  const { data: influencer } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
    staleTime: Infinity,
  });

  const {
    data: queryData,
    // isFetching,
  } = useQuery({
    queryKey: ['messenger-data'],
    queryFn: () => getMessages(),
    refetchInterval: (_, query) =>
      query.state.error ? 0 : parseInt(refetchIntervalValue, 10),
  });

  const { data: igConversations, igData: igDataObj } = queryData || {};

  const { is_instagram: isInstaVerified } = influencer || {};

  return (
    <div className={styles.settingCounter}>
      <div
        className={styles.notifyHeader}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 className={styles.notifyTxt}>{igDataObj?.name}</h3>
        <div className={styles.chatInput} style={{ display: 'flex' }}>
          <input
            type="text"
            defaultValue={`${refetchIntervalValue / 1000} sec`}
            id="refetchIntervalValueInput"
            style={{ width: '5vw' }}
          />
          <button
            onClick={() => {
              setRefetchIntervalValue(
                document
                  .getElementById('refetchIntervalValueInput')
                  .value.split('sec')[0] * 1000
              );
            }}
          >
            Set
          </button>
        </div>
      </div>
      {/* {console.log({ igConversations }, { igDataObj })} */}
      {isInstaVerified && !activeConversation && (
        <table className={styles.billingTable}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }} scope="col">
                members
              </th>
              <th style={{ textAlign: 'left' }} scope="col">
                messages
              </th>
              <th style={{ textAlign: 'left' }} scope="col">
                timestamp
              </th>
            </tr>
          </thead>
          <tbody>
            {igConversations?.map((conversation) => {
              let row = '';
              const party = conversation?.participants?.data
                .map((participant) => participant.username)
                .filter((participant) => participant !== igDataObj.username)
                .join(', ');
              const date = new Date(conversation.messages.data[0].created_time);
              let snippet = conversation?.messages?.data[0].message;
              if (snippet.length > 24) snippet = `${snippet.slice(0, 24)}...`;
              row = (
                <tr
                  key={conversation.id}
                  onClick={() => {
                    handleConversationClick(conversation);
                  }}
                >
                  <td>{party.length > 1 ? party : ['Me']}</td>
                  <td>{snippet}</td>
                  <td>
                    <p>{date.toLocaleDateString()}</p>
                    <p>{date.toLocaleTimeString()}</p>
                  </td>
                </tr>
              );
              return row;
            })}
          </tbody>
        </table>
      )}
      {/* Overlay Chat Window */}
      {activeConversation && (
        <div className={styles.chatWindowContainerBase}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className={styles.notifyTxt}>
              {queryData.data
                .filter((chat) => chat.id === activeConversation)[0]
                .participants?.data.map((participant) => participant.username)
                .join(', ')}
            </h3>
            <button
              type="button"
              className={styles.closeButton}
              id="closeButton"
              onClick={handleCloseChat}
            >
              &times;
            </button>
          </div>
          <ChatWindow {...{ queryData, activeConversation, igDataObj }} />
          <form
            className={styles.chatInput}
            onSubmit={(event) => {
              sendMessage(event);
            }}
          >
            <input
              type="text"
              placeholder="Type a message..."
              id="messageInput"
            />
            <button
              type="submit"
              id="sendButton"
              onClick={(event) => {
                sendMessage(event);
              }}
            >
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Messenger;
