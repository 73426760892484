import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';
import { editSocialHandles, getProfile } from 'api/influencer/profile';

import Footer from 'components/onboarding/Footer';
import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';

import useTwitterAuth from 'hooks/useTwitterAuth';
import useYoutubeAuth from 'hooks/useYoutubeAuth';
import useFacebookAuth from 'hooks/useFacebookAuth';
import useInstagramAuth from 'hooks/useInstagramAuth';

import routes from 'constants/routes';
import { INFLUENCER_ADD_SOCIAL_HANDLES_SCHEMA } from 'constants/schema';
import { logoImageBlackText } from 'constants/images';
import styles from 'assets/scss/pages/onboarding.module.scss';
import instagramAnimatedIcon from 'assets/lottie/instagram-icon.json';
import facebookAnimatedIcon from 'assets/lottie/facebook-icon.json';
import youtubeAnimatedIcon from 'assets/lottie/youtube-icon.json';
import twitterAnimatedIcon from 'assets/lottie/twitter-icon.json';

const SignUpLinkSocialAccounts = () => {
  const { data } = useQuery({
    queryKey: ['influencer-profile'],
    queryFn: () => getProfile(),
  });

  const {
    facebook_id: facebookId,
    instagram_id: instagramId,
    youtube_id: youtubeId,
    twitter_id: twitterId,
    is_facebook: isFacebookVerified,
    is_instagram: isInstaVerified,
    // is_twitter: isTwitterVerified,
    // is_youtube: isYoutubeVerified,
  } = data || {};

  // const isLinkedAtleastOneAccount =
  //   isFacebookVerified ||
  //   isInstaVerified ||
  //   isTwitterVerified ||
  //   isYoutubeVerified;

  return (
    <>
      <div className={styles.signupInnerContent}>
        <div className={styles.signupItems}>
          <div>
            <div style={{ width: 'fit-content' }}>
              <Link to={routes.HOME}>
                <img src={logoImageBlackText} alt="" width={140} />
              </Link>
            </div>
            <div className={styles.linkSocialInfo}>
              <h1>Link your socials</h1>
              <Tooltip label="Users must verify their accounts if their account passwords are changed or if authorization has been revoked from social media platform settings." />
            </div>

            <p>The more accounts you link, the more users you'll get.</p>
          </div>
        </div>
        <HandlesFlow
          facebookId={facebookId}
          instagramId={instagramId}
          twitterId={twitterId}
          youtubeId={youtubeId}
          isFacebookVerified={isFacebookVerified}
          isInstaVerified={isInstaVerified}
        />
        <Footer type="influencer" />
      </div>
    </>
  );
};

const defaultValues = {
  instagram: '',
  facebook: '',
  youtube: '',
  twitter: '',
};

const HandlesFlow = ({
  facebookId,
  instagramId,
  twitterId,
  youtubeId,
  isFacebookVerified,
  isInstaVerified,
}) => {
  const navigate = useNavigate();
  const { onFacebookLogin, unlinkFacebook } = useFacebookAuth();
  const { onInstagramLogin, unlinkInstagram } = useInstagramAuth();

  const { register, handleSubmit, reset } = useForm({
    // resolver: yupResolver(INFLUENCER_ADD_SOCIAL_HANDLES_SCHEMA),
    defaultValues,
  });

  useEffect(() => {
    reset({
      facebookId: facebookId ?? '',
      instagramId: instagramId ?? '',
      twitterId: twitterId ?? '',
      youtubeId: youtubeId ?? '',
    });
  }, [reset, facebookId, instagramId, twitterId, youtubeId]);

  const editSocialAccountsMutation = useMutation({
    mutationFn: ({ instagramId, facebookId, twitterId, youtubeId }) =>
      editSocialHandles({
        // .replace(/\s/g, '') will remove all spaces to prevent from error
        instagram_id: instagramId.replace(/\s/g, ''),
        youtube_id: youtubeId.replace(/\s/g, ''),
        facebook_id: facebookId.replace(/\s/g, ''),
        twitter_id: twitterId.replace(/\s/g, ''),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['influencer-profile'] });
      reset();
      navigate(routes.INF_SIGNUP_ADD_RATE_CARD);
    },
  });

  return (
    <form
      className={styles.signupFormItems}
      onSubmit={handleSubmit(editSocialAccountsMutation.mutate)}
    >
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={facebookAnimatedIcon} />
        </div>
        {/* <div className={styles.withHelperText}>
          <input
            type="text"
            placeholder="Enter Facebook Username"
            {...register('facebookId')}
          />

          <p className={styles.helperText}>
            From your Facebook profile, click on your profile photo in the top
            right corner. Click on 'Settings and privacy', then 'Settings'. In
            the General profile settings, find and click on 'Username.' For
            example: priyakanta.laish
          </p>
        </div> */}
        <div className={styles.socialInputWrapper}>
          <input
            type="text"
            placeholder="Enter Facebook Username"
            {...register('facebookId')}
            hidden
          />
          <h2 className={styles.socialTxt}>Facebook</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isFacebookVerified,
              })}
              onMouseOver={(event) => {
                if (event.target.textContent !== 'Verify') {
                  event.target.textContent = 'Unlink';
                }
              }}
              onMouseOut={(event) => {
                event.target.textContent = isFacebookVerified
                  ? 'Verified'
                  : 'Verify';
              }}
              onBlur={() => {}}
              onFocus={() => {}}
              onClick={() => {
                if (isFacebookVerified) {
                  unlinkFacebook();
                  window.location.reload();
                } else {
                  onFacebookLogin();
                }
              }}
              // disabled={isFacebookVerified}
            >
              {isFacebookVerified ? 'Verified' : 'Verify'}
            </button>
            {isFacebookVerified ? (
              <button
                type="button"
                className={styles.verify}
                onClick={onFacebookLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={instagramAnimatedIcon} />
        </div>
        <div className={styles.socialInputWrapper}>
          <input
            type="text"
            placeholder="Enter Instagram Username"
            {...register('instagramId')}
            hidden
          />
          <h2 className={styles.socialTxt}>Instagram</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isInstaVerified,
              })}
              onMouseOver={(event) => {
                if (event.target.textContent !== 'Verify') {
                  event.target.textContent = 'Unlink';
                }
              }}
              onMouseOut={(event) => {
                event.target.textContent = isInstaVerified
                  ? 'Verified'
                  : 'Verify';
              }}
              onBlur={() => {}}
              onFocus={() => {}}
              onClick={() => {
                if (isInstaVerified) {
                  unlinkInstagram();
                  window.location.reload();
                } else {
                  onInstagramLogin();
                }
              }}
              // disabled={isInstaVerified}
            >
              {isInstaVerified ? 'Verified' : 'Verify'}
            </button>
            {isInstaVerified ? (
              <button
                type="button"
                className={styles.verify}
                onClick={onInstagramLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
          {/* <p className={styles.helperText}>For example: jmeeta_</p> */}
        </div>
      </div>

      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player
            loop
            autoplay
            src={youtubeAnimatedIcon}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              transform: 'scale(2.1)',
            }}
          />
        </div>
        <div className={styles.withHelperText}>
          <input
            type="text"
            placeholder="Enter YouTube Channel ID"
            {...register('youtubeId')}
          />
          <p className={styles.helperText}>
            Open YouTube and go to your channel. Click on your profile image in
            the top right corner, then click on "Settings." Next, click on "View
            Advanced settings." You&apos; ll find your Channel ID or User ID
            there. For example: UC8CQ8cxKlYPSo-0sJfGHrEA
          </p>
        </div>
      </div>
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={twitterAnimatedIcon} />
        </div>
        <div className={styles.withHelperText}>
          <input
            type="text"
            placeholder="Enter Twitter Id"
            {...register('twitterId')}
          />
          <p className={styles.helperText}>
            Tap the Profile button in the quick navigation panel on the left. If
            you're using X on the mobile app, swipe right to see the quick
            navigation panel. Your Twitter username, starting with '@', will be
            underneath your name. For example: Aashna Shroff
          </p>
        </div>
      </div>
      <Button
        type="submit"
        size="large"
        fullWidth
        isLoading={editSocialAccountsMutation.isLoading}
      >
        Submit
      </Button>
    </form>
  );
};

HandlesFlow.propTypes = {
  facebookId: PropTypes.string,
  instagramId: PropTypes.string,
  twitterId: PropTypes.string,
  youtubeId: PropTypes.string,
  isFacebookVerified: PropTypes.bool,
  isInstaVerified: PropTypes.bool,
};

const SocialAuthFlow = ({
  isFacebookVerified,
  isInstaVerified,
  isTwitterVerified,
  isYoutubeVerified,
  isLinkedAtleastOneAccount,
}) => {
  const navigate = useNavigate();

  const { onTwitterLogin } = useTwitterAuth();
  const { onYoutubeLogin } = useYoutubeAuth();
  const { onFacebookLogin } = useFacebookAuth();
  const { onInstagramLogin } = useInstagramAuth();

  const onSubmit = () => {
    navigate(routes.INF_SIGNUP_ADD_RATE_CARD);
  };

  return (
    <div className={styles.signupFormItems}>
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={instagramAnimatedIcon} />
        </div>
        <div className={styles.socialInputWrapper}>
          <h2 className={styles.socialTxt}>Instagram</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isInstaVerified,
              })}
              onClick={onInstagramLogin}
              disabled={isInstaVerified}
            >
              {isInstaVerified ? 'Verified' : 'Verify'}
            </button>
            {isInstaVerified ? (
              <button
                type="button"
                className={clsx(styles.verify)}
                onClick={onInstagramLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={facebookAnimatedIcon} />
        </div>
        <div className={styles.socialInputWrapper}>
          <h2 className={styles.socialTxt}>Facebook</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isFacebookVerified,
              })}
              onClick={onFacebookLogin}
              disabled={isFacebookVerified}
            >
              {isFacebookVerified ? 'Verified' : 'Verify'}
            </button>
            {isFacebookVerified ? (
              <button
                type="button"
                className={styles.verify}
                onClick={onFacebookLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player
            loop
            autoplay
            src={youtubeAnimatedIcon}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              transform: 'scale(2.1)',
            }}
          />
        </div>
        <div className={styles.socialInputWrapper}>
          <h2 className={styles.socialTxt}>Youtube</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isYoutubeVerified,
              })}
              onClick={onYoutubeLogin}
              disabled={isYoutubeVerified}
            >
              {isYoutubeVerified ? 'Verified' : 'Verify'}
            </button>
            {isYoutubeVerified ? (
              <button
                type="button"
                className={styles.verify}
                onClick={onYoutubeLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.accountLink}>
        <div className={styles.socialImage}>
          <Player loop autoplay src={twitterAnimatedIcon} />
        </div>
        <div className={styles.socialInputWrapper}>
          <h2 className={styles.socialTxt}>Twitter</h2>
          <div className={styles.verifyBtnWrapper}>
            <button
              type="button"
              className={clsx({
                [styles.verify]: true,
                [styles.activeVerifyBtn]: isTwitterVerified,
              })}
              onClick={onTwitterLogin}
              disabled={isTwitterVerified}
            >
              {isTwitterVerified ? 'Verified' : 'Verify'}
            </button>
            {isTwitterVerified ? (
              <button
                type="button"
                className={styles.verify}
                onClick={onTwitterLogin}
              >
                Reverify
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <Button
        size="large"
        fullWidth
        onClick={onSubmit}
        disabled={!isLinkedAtleastOneAccount}
      >
        Submit
      </Button>
    </div>
  );
};

SocialAuthFlow.propTypes = {
  isFacebookVerified: PropTypes.bool,
  isInstaVerified: PropTypes.bool,
  isTwitterVerified: PropTypes.bool,
  isYoutubeVerified: PropTypes.bool,
  isLinkedAtleastOneAccount: PropTypes.bool,
};

export default SignUpLinkSocialAccounts;
