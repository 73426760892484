import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Card, Paper, Stack, Typography, Chip, Button } from '@mui/material';
import { Element } from 'react-scroll';
import {
  AudienceSection,
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfContentPerformanceAndBenchmark,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
  GrowthChart,
  BrandAffinityInterest,
  FollowerType,
} from 'components/common/inf-profile';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  instaReels,
  instaVideos,
  platformIcon,
} from 'constants/images';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getInfluencerDetails,
  getInfluencerRank,
} from 'api/brand/search';
import { formatNumber } from 'utils';
import { NoData } from 'components/common/NoData';
import useFormatInstaAgeGenderData from 'hooks/useFormatInstaAgeGenderData';
import useFromatInstaCountryData from 'hooks/useFromatInstaCountryData';
import useFormatInstaCityData from 'hooks/useFormatInstaCityData';

const YoutubeProfile = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { id: influencerId } = useParams();

  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['brand-influencer-rank', influencerId],
    queryFn: () => getInfluencerRank({ influencerId }),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: [
        'brand-influencer-follower-growth',
        influencerId,
        'youtube',
        follersGrowthFilter,
      ],
      queryFn: () => {
        const params = {
          influencer_id: influencerId,
          follower_growth_filter: follersGrowthFilter,
          platform: 'youtube',
        };
        return getFollowersGrowth(params);
      },
    });
  console.log('🚀 ~ YoutubeProfile ~ followerGrowthData:', followerGrowthData);

  const { youtubeProfile } = influencer || {};
  const {
    shortsStats,
    videoStats,
    brandPosts,
    recentPosts,
    topPosts,
    hashtags,
    brands,
    audienceByAgeGroupAndGenderRaw,
    audienceByCountryRaw,
    audienceByCityRaw,
    growthDataRaw,
    audienceTypesRaw,
    audienceBrandsRaw,
    audienceInterestsRaw,
    audienceLanguagesRaw,
    audienceEthnicitiesRaw,
    formattedCreatorInterests,
    frequentlyTaggedAccounts = [],
  } = youtubeProfile || {};

  const contentPerformance = useMemo(
    () =>
      recentPosts?.map((video) => ({
        videoId: video.postId,
        views: Number(video.viewsCount),
      })),
    [recentPosts]
  );

  const { audienceByAgeGroup, audienceByGender } = useFormatInstaAgeGenderData(
    audienceByAgeGroupAndGenderRaw
  );
  const audienceByCountry = useFromatInstaCountryData(audienceByCountryRaw);
  const { audienceByCity } = useFormatInstaCityData(audienceByCityRaw);

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          // { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Subscribers"
          value={formatNumber(youtubeProfile?.followers)}
        />
        <OverviewCard
          title="Engagement Rate"
          value={formatNumber(youtubeProfile?.avgEngagementRate)}
        />
        <OverviewCard title="Sponsor Post Performance" value={0} />

        {/* <OverviewCard
          title="SMINCO Score"
          value={formatNumber(youtubeProfile?.smincoScore)}
          chipValue={infleucnerRank?.topRankText}
          info="Platform Score"
        /> */}
      </Element>

      <Element className={styles.navContentSection} name="overview">
        <OverviewCard
          title="Average Likes"
          value={formatNumber(youtubeProfile?.avgLikes)}
        />
        <OverviewCard
          title="Average Comments"
          value={formatNumber(youtubeProfile?.avgComments)}
        />
        <OverviewCard
          title="Average Views"
          value={formatNumber(youtubeProfile?.avgViews)}
        />
        <OverviewCard
          title="Total Media"
          value={formatNumber(youtubeProfile?.totalMedia)}
        />
      </Element>
      <Element
        className={`${styles.navCategory} ${styles.engagementSectionPadding}`}
        name="engagement"
      >
        <div className={styles.sectionHeader}>
          <img src={heartGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Engagements & Views</h2>
        </div>
        <div className={clsx(styles.sectionBody, styles.engamntBody)}>
          <EngagementCard
            icon={instaVideos}
            title="Videos"
            items={[
              // {
              //   name: 'Total Videos',
              //   value: formatNumber(videoStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(videoStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(videoStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(videoStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(videoStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
          <EngagementCard
            icon={instaReels}
            title="Shorts"
            items={[
              // {
              //   name: 'Total Shorts',
              //   value: formatNumber(shortsStats?.total),
              // },
              {
                name: 'Avg. Views',
                value: formatNumber(shortsStats?.avgViews),
              },
              {
                name: 'Avg. Likes',
                value: formatNumber(shortsStats?.avgLikes),
              },
              {
                name: 'Avg. Comments',
                value: formatNumber(shortsStats?.avgComments),
              },
              {
                name: 'Likes-Comments Ratio ',
                value: formatNumber(shortsStats?.likeCommentRatio, {
                  maximumFractionDigits: 2,
                }),
              },
            ]}
          />
        </div>
      </Element>
      {/* <Element name="growth">
        <InfFollowerGrowth
          data={followerGrowthData}
          isLoading={isLoadingFollowersGrowth}
          handleFilterChange={setFollersGrowthFilter}
          filter={follersGrowthFilter}
        />
      </Element> */}

      {/* Growth Charts */}
      {youtubeProfile?.growthDataRaw &&
        youtubeProfile?.growthDataRaw.length > 0 && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ width: '100%' }}
          >
            {youtubeProfile?.growthDataRaw.some(
              (item) => item.subscribers > 0
            ) && (
              <GrowthChart
                title="Subscribers Growth"
                subtitle="Monthly Subscribers Trend"
                data={youtubeProfile?.growthDataRaw}
                xKey="date"
                yKey="subscribers"
                color="#7C087F"
              />
            )}
            {youtubeProfile?.growthDataRaw.some((item) => item.likes > 0) && (
              <GrowthChart
                title="Likes Growth"
                subtitle="Monthly Like Trend Per Post"
                data={youtubeProfile?.growthDataRaw}
                xKey="date"
                yKey="likes"
                color="#7C087F"
              />
            )}
          </Stack>
        )}

      <AudienceSection
        audienceByCity={audienceByCity}
        audienceByCountry={audienceByCountry}
        audienceByGender={audienceByGender}
        audienceByAgeGroup={audienceByAgeGroup}
      />

      <InfContentPerformanceAndBenchmark
        data={contentPerformance}
        valueKey="views"
        influencerAverage={youtubeProfile?.avgViews}
      />

      <Element name="content">
        <ContentSection
          brandPosts={brandPosts}
          hashtags={hashtags}
          recentPosts={recentPosts}
          topPosts={topPosts}
        />
      </Element>
      <Element name="brands">
        <BrandsSection
          brands={brands}
          platform="youtube"
          variant="brand"
          influencerId={influencerId}
        />
      </Element>
      {!youtubeProfile.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default YoutubeProfile;
