import { unlinkFacebookAPI } from '../api/influencer/social';

const useFacebookAuth = () => {
  const onFacebookLogin = () => {
    const facebookAuthUrl = new URL(
      'https://www.facebook.com/v16.0/dialog/oauth'
    );

    facebookAuthUrl.searchParams.append('response_type', 'code');
    facebookAuthUrl.searchParams.append(
      'client_id',
      process.env.REACT_APP_FB_CLIENT_ID
    );
    facebookAuthUrl.searchParams.append(
      'redirect_uri',
      process.env.REACT_APP_FB_REDIRECT_URL
    );
    facebookAuthUrl.searchParams.append(
      'scope',
      process.env.REACT_APP_FB_AUTH_SCOPE
    );
    facebookAuthUrl.searchParams.append(
      'config_id',
      process.env.REACT_APP_FB_CONFIGURATION_ID
    );
    facebookAuthUrl.searchParams.append('state', 'state'); // TODO: make this dynamic it should be unique for each user
    window.location = facebookAuthUrl;
  };

  const unlinkFacebook = () => {
    // call to API to remove facebook account
    unlinkFacebookAPI();
  };

  return { onFacebookLogin, unlinkFacebook };
};

export default useFacebookAuth;
