import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { RootErrorBoundary } from 'components/common/Error';
// App is root component that will render for each router
import App from 'App';
// Auth guards
import InfluencerAuthGuard from 'router/InfluencerAuthGuard';
import InfluencerGuestGuard from 'router/InfluencerGuestGuard';
import BrandAuthGuard from 'router/BrandAuthGuard';
import BrandGuestGuard from 'router/BrandGuestGuard';
// layouts
import DefaultLayout from 'layouts/default';
import BrandDefaultLayout from 'layouts/default/brand';
import InfluencerDefaultLayout from 'layouts/default/influencer';
import OnboardingLayout from 'layouts/onboarding';
import BrandDashboardLayout from 'layouts/dashboard/Brand';
import InfluencerDashboardLayout from 'layouts/dashboard/Influencer';
// pages
import Home from 'pages/home';
import Subscription from 'pages/subscription';
import PrivacyPolicy from 'pages/privacy-policy';
import BrandTermsAndConditions from 'pages/terms/Brand';
import InfluencerTermsAndConditions from 'pages/terms/Influencer';
import ContactUs from 'pages/contact-us';
import Faq from 'pages/faq';
import HelpAndSupport from 'pages/help-and-support';
import About from 'pages/about';
import CaseStudies from 'pages/case-studies';
import CaseStudyDetails from 'pages/case-studies/CaseStudyDetails';
import Payments from 'pages/payments';

import BrandHome from 'pages/brand';
import BrandSignUp from 'pages/brand/SignUp';
import BrandSignUpVerify from 'pages/brand/SignUpVerifyOtp';
import BrandSignUpProfileSetup from 'pages/brand/SignUpProfileSetup';
import BrandSignUpAddAddress from 'pages/brand/SignUpAddAddress';
import BrandLogin from 'pages/brand/Login';

import BrandDashboard from 'pages/brand/dashboard';
import BrandSearch from 'pages/brand/dashboard/search';
import BrandSearchInfCategories from 'pages/brand/dashboard/search/InfluencerCategories';
import BrandSearchInfList from 'pages/brand/dashboard/search/InfluencerList';
import BrandSearchCustomList from 'pages/brand/dashboard/search/custom-list';
import BrandSearchCustomListDetails from 'pages/brand/dashboard/search/custom-list/ListDetails';
import BrandInfluencerProfile from 'pages/brand/dashboard/search/influencer-profile';
import BrandInfluencerProfileSummary from 'pages/brand/dashboard/search/influencer-profile/Summary';
import BrandInfluencerProfileInstagram from 'pages/brand/dashboard/search/influencer-profile/Instagram';
import BrandInfluencerProfileFacebook from 'pages/brand/dashboard/search/influencer-profile/Facebook';
import BrandInfluencerProfileTwitter from 'pages/brand/dashboard/search/influencer-profile/Twitter';
import BrandInfluencerProfileYoutube from 'pages/brand/dashboard/search/influencer-profile/Youtube';
import BrandCompareInfluencer from 'pages/brand/dashboard/search/CompareInfluencers';
import BrandCampaigns from 'pages/brand/dashboard/campaigns';
import BrandCampaignDetails from 'pages/brand/dashboard/campaigns/campaign-details';
import BrandCampaignInfluencers from 'pages/brand/dashboard/campaigns/campaign-details/CampaignInfluencers';
import BrandCampaignReports from 'pages/brand/dashboard/campaigns/campaign-details/CampaignReports';
import BrandCampaignMeetingNotes from 'pages/brand/dashboard/campaigns/campaign-details/MeetingNotes';
import BrandCampaignQuotationDetail from 'pages/brand/dashboard/campaigns/campaign-details/QuotationDetails';
import BrandCampaignPaymentAndCheckout from 'pages/brand/dashboard/campaigns/campaign-details/PaymentAndCheckout';
import BrandNotifications from 'pages/brand/dashboard/Notifications';

import BrandProfile from 'pages/brand/dashboard/profile';
import BrandProfileSetting from 'pages/brand/dashboard/profile/Setting';
import BrandProfileSubscription from 'pages/brand/dashboard/profile/Subscription';
import BrandAnalyseBrands from 'pages/brand/dashboard/brand-analysis';
import BrandAnalyseBrandDetails from 'pages/brand/dashboard/brand-analysis/BrandDetails';
import BrandAnalyseBrandPosts from 'pages/brand/dashboard/brand-analysis/BrandDetails/BrandPosts';
import BrandAnalyseBrandInfluencers from 'pages/brand/dashboard/brand-analysis/BrandDetails/InfluencerList';

import InfluencerHome from 'pages/influencer';
import InfluencerSignUp from 'pages/influencer/SignUp';
import InfluencerSignUpVerify from 'pages/influencer/SignUpVerifyOtp';
import InfluencerProfileSetup from 'pages/influencer/SignUpProfileSetup';
import InfluencerSignUpAddAddress from 'pages/influencer/SignUpAddAddress';
import InfluencerSignUpLinkSocial from 'pages/influencer/SignUpLinkSocialAccounts';
import SignupAddRateCard from 'pages/influencer/SignupAddRateCard';
import InfluencerLogin from 'pages/influencer/Login';
import InfluencerForgotPassword from 'pages/influencer/ForgotPassword';
import InfluencerResetPassword from 'pages/influencer/ResetPassword';

import InfluencerDashboard from 'pages/influencer/dashboard';
import InfluencerCampaigns from 'pages/influencer/dashboard/campaigns';
import InfluencerCampaignsDetails from 'pages/influencer/dashboard/campaigns/campaign-details';
import InfluencerCampaignsDeliverables from 'pages/influencer/dashboard/campaigns/campaign-details/Deliverables';
import InfluencerCampaignsDeliverablesPlatform from 'pages/influencer/dashboard/campaigns/campaign-details/Deliverables/PlatformDeliverables';
import InfluencerCampaignsAnalytics from 'pages/influencer/dashboard/campaigns/campaign-details/Analytics';
import InfluencerCampaignReports from 'pages/influencer/dashboard/CampaignReports';
import InfluencerSelfAnalytics from 'pages/influencer/dashboard/analyse-profile';
import InfluencerSelfAnalyticsSummary from 'pages/influencer/dashboard/analyse-profile/Summary';
import InfluencerSelfAnalyticsInstagram from 'pages/influencer/dashboard/analyse-profile/Instagram';
import InfluencerSelfAnalyticsYoutube from 'pages/influencer/dashboard/analyse-profile/Youtube';
import InfluencerSelfAnalyticsFacebook from 'pages/influencer/dashboard/analyse-profile/Facebook';
import InfluencerSelfAnalyticsTwitter from 'pages/influencer/dashboard/analyse-profile/Twitter';
import InfluencerNotifications from 'pages/influencer/dashboard/Notifications';
import InfluencerProfile from 'pages/influencer/dashboard/profile';
import InfluencerProfileSettings from 'pages/influencer/dashboard/profile/Settings';
import InfluencerProfileRateCard from 'pages/influencer/dashboard/profile/RateCard';
import InfluencerProfileMessenger from 'pages/influencer/dashboard/profile/Messenger';

// redirects
import FacebookRedirect from 'pages/redirects/Facebook';
import InstagramRedirect from 'pages/redirects/Instagram';
import TwitterRedirect from 'pages/redirects/Twitter';
import YoutubeRedirect from 'pages/redirects/Youtube';
import Docusign from 'pages/redirects/Docusign';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <RootErrorBoundary />,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/',
        element: <DefaultLayout />,
        children: [
          // { path: 'subscription', element: <Subscription /> },
          { path: 'faq', element: <Faq /> },
          { path: 'privacy-policy', element: <PrivacyPolicy /> },
          { path: 'about-us', element: <About /> },
          { path: 'help-and-support', element: <HelpAndSupport /> },
          {
            path: 'terms-and-conditions',
            children: [
              { path: 'brand', element: <BrandTermsAndConditions /> },
              { path: 'influencer', element: <InfluencerTermsAndConditions /> },
            ],
          },
          { path: 'contact-us', element: <ContactUs /> },
          {
            path: 'case-studies',
            children: [
              { index: true, element: <CaseStudies /> },
              { path: ':caseStudyId', element: <CaseStudyDetails /> },
            ],
          },
          /**
           * * module is a section or feature of website for which we are doing payment
           * * For eg. "campaign", "brand-subscription" etc.
           *  */
          { path: 'payments/:paymentId/:moduleId', element: <Payments /> },
        ],
      },

      // Brand routes
      {
        path: 'brand',
        element: <Outlet />,
        children: [
          { index: true, element: <BrandHome /> },
          {
            path: 'help-and-support',
            element: <BrandDefaultLayout />,
            children: [{ index: true, element: <HelpAndSupport /> }],
          },
          // brand dashboard routes
          {
            path: 'dashboard',
            element: <BrandAuthGuard children={<BrandDashboardLayout />} />,
            children: [
              { index: true, element: <BrandDashboard /> },
              {
                path: 'search/',
                element: <BrandSearch />,
                children: [
                  { index: true, element: <BrandSearchInfCategories /> },
                  { path: 'inf-list', element: <BrandSearchInfList /> },
                  {
                    path: 'custom-list',
                    element: <Outlet />,
                    children: [
                      { index: true, element: <BrandSearchCustomList /> },
                      { path: ':id', element: <BrandSearchCustomListDetails /> },
                    ],
                  },
                ],
              },
              {
                path: 'search/:id',
                element: <BrandInfluencerProfile />,
                children: [
                  { index: true, element: <BrandInfluencerProfileSummary /> },
                  { path: 'instagram', element: <BrandInfluencerProfileInstagram /> },
                  { path: 'facebook', element: <BrandInfluencerProfileFacebook /> },
                  { path: 'twitter', element: <BrandInfluencerProfileTwitter /> },
                  { path: 'youtube', element: <BrandInfluencerProfileYoutube /> },
                ],
              },
              { path: 'search/compare-influencers', element: <BrandCompareInfluencer /> },
              {
                path: 'campaigns',
                element: <Outlet />,
                children: [
                  { index: true, element: <BrandCampaigns /> },
                  {
                    path: ':id',
                    element: <Outlet />,
                    children: [
                      {
                        element: <BrandCampaignDetails />,
                        children: [
                          { index: true, element: <BrandCampaignInfluencers /> },
                          { path: 'reports', element: <BrandCampaignReports /> },
                          { path: 'meeting-notes', element: <BrandCampaignMeetingNotes /> },
                        ],
                      },
                      {
                        path: ':quoteId',
                        element: <Outlet />,
                        children: [
                          { index: true, element: <BrandCampaignQuotationDetail /> },
                          { path: 'payment-and-checkout', element: <BrandCampaignPaymentAndCheckout /> },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'brand-analysis',
                element: <Outlet />,
                children: [
                  { index: true, element: <BrandAnalyseBrands /> },
                  {
                    path: ':id/:platform',
                    element: <BrandAnalyseBrandDetails />,
                    children: [
                      { index: true, element: <BrandAnalyseBrandPosts /> },
                      { path: 'influencers', element: <BrandAnalyseBrandInfluencers /> },
                    ],
                  },
                ],
              },
              { path: 'notifications', element: <BrandNotifications /> },

              {
                path: 'profile',
                element: <BrandProfile />,
                children: [
                  { index: true, element: <BrandProfileSetting /> },
                  { path: 'subscription', element: <BrandProfileSubscription /> },
                ],
              },
            ],
          },
          // brand onboarding routes
          {
            element: <OnboardingLayout />,
            children: [
              { path: 'signup', element: <BrandGuestGuard children={<BrandSignUp />} /> },
              { path: 'signup-verify', element: <BrandSignUpVerify /> },
              {
                path: 'signup-profile-setup',
                element: (
                  <BrandAuthGuard
                    children={<BrandSignUpProfileSetup />}
                    checkAgencyName={false}
                    checkForAddress={false}
                  />
                ),
              },
              {
                path: 'signup-add-address',
                element: (
                  <BrandAuthGuard
                    children={<BrandSignUpAddAddress />}
                    checkForAddress={false}
                    checkAgencyName={false}
                  />
                ),
              },
              { path: 'login', element: <BrandGuestGuard children={<BrandLogin />} /> },
            ],
          },
        ],
      },

      // influencer routes
      {
        path: 'influencer',
        element: <Outlet />,
        children: [
          { index: true, element: <InfluencerHome /> },
          {
            path: 'help-and-support',
            element: <InfluencerDefaultLayout />,
            children: [{ index: true, element: <HelpAndSupport /> }],
          },
          // influencer dashboard routes
          {
            path: 'dashboard',
            element: <InfluencerAuthGuard children={<InfluencerDashboardLayout />} />,
            children: [
              { index: true, element: <InfluencerDashboard /> },
              {
                path: 'campaigns',
                element: <Outlet />,
                children: [
                  { index: true, element: <InfluencerCampaigns /> },
                  {
                    path: ':id',
                    element: <InfluencerCampaignsDetails />,
                    children: [
                      { index: true, element: <Navigate to="deliverables" replace /> },
                      {
                        path: 'deliverables',
                        element: <InfluencerCampaignsDeliverables />,
                        children: [
                          { index: true, element: <Navigate to="instagram" replace /> },
                          { path: ':platform', element: <InfluencerCampaignsDeliverablesPlatform /> },
                        ],
                      },
                      { path: 'analytics', element: <InfluencerCampaignsAnalytics /> },
                    ],
                  },
                ],
              },
              { path: 'reports', element: <InfluencerCampaignReports /> },
              {
                path: 'analyse-self-profile',
                element: <InfluencerSelfAnalytics />,
                children: [
                  { index: true, element: <InfluencerSelfAnalyticsSummary /> },
                  { path: 'instagram', element: <InfluencerSelfAnalyticsInstagram /> },
                  { path: 'facebook', element: <InfluencerSelfAnalyticsFacebook /> },
                  { path: 'twitter', element: <InfluencerSelfAnalyticsTwitter /> },
                  { path: 'youtube', element: <InfluencerSelfAnalyticsYoutube /> },
                ],
              },
              { path: 'notifications', element: <InfluencerNotifications /> },
              {
                path: 'profile',
                element: <InfluencerProfile />,
                children: [
                  { index: true, element: <InfluencerProfileSettings /> },
                  { path: 'rate-card', element: <InfluencerProfileRateCard /> },
                  { path: 'messenger', element: <InfluencerProfileMessenger /> },
                ],
              },
            ],
          },

          // influencer onboarding routes
          {
            element: <OnboardingLayout />,
            children: [
              { path: 'login', element: <InfluencerGuestGuard children={<InfluencerLogin />} /> },
              { path: 'signup', element: <InfluencerGuestGuard children={<InfluencerSignUp />} /> },
              { path: 'signup-verify', element: <InfluencerSignUpVerify /> },
              { path: 'signup-profile-setup', element: <InfluencerProfileSetup /> },
              {
                path: 'signup-add-address',
                element: (
                  <InfluencerAuthGuard
                    children={<InfluencerSignUpAddAddress />}
                    checkForAddress={false}
                    checkForSocial={false}
                  />
                ),
              },
              {
                path: 'signup-add-social',
                element: (
                  <InfluencerAuthGuard
                    children={<InfluencerSignUpLinkSocial />}
                    checkForAddress={false}
                    checkForSocial={false}
                  />
                ),
              },
              {
                path: 'signup-add-rate-card',
                element: (
                  <InfluencerAuthGuard children={<SignupAddRateCard />} checkForAddress={false} checkForSocial />
                ),
              },
              { path: 'forgot-password', element: <InfluencerGuestGuard children={<InfluencerForgotPassword />} /> },
              { path: 'reset-password', element: <InfluencerResetPassword /> },
            ],
          },
        ],
      },

      {
        path: 'redirects',
        element: <Outlet />,
        children: [
          { path: 'facebook', element: <FacebookRedirect /> },
          { path: 'youtube', element: <YoutubeRedirect /> },
          { path: 'instagram', element: <InstagramRedirect /> },
          { path: 'twitter', element: <TwitterRedirect /> },
          { path: 'docusign/:userType', element: <Docusign /> },
        ],
      },
    ],
  },
]);

export default router;
