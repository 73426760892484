import React from 'react';
import styles from 'assets/scss/pages/profile-dashboard.module.scss';

const ChatWindow = (chatWindowData) => {
  const { queryData, activeConversation, igDataObj } = chatWindowData || {};
  const chat = queryData.data.filter(
    (chat) => chat.id === activeConversation
  )[0];
  return (
    <div className={styles.chatWindowContainer}>
      <div className={styles.chatWindow}>
        <div className={styles.chatMessages}>
          {chat?.messages.data.toReversed().map((message) => {
            return (
              <div
                key={message.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {/* chat.messages.data[0].message */}
                {message.from.username !== igDataObj.username ? (
                  // Received
                  <div className={styles.messageReceived}>
                    <div className={styles.messageReceivedUsername}>
                      {message.from.username}
                    </div>
                    <p>{message.message}</p>
                    <div className={styles.messageTS}>
                      {message.created_time
                        .split('T')
                        .map((item) => {
                          return item.includes('-')
                            ? item.split('-').slice(1, 3).toReversed().join('-')
                            : item.split(':').slice(0, 2).join(':');
                        })
                        .toReversed()
                        .join(' ')}
                    </div>
                  </div>
                ) : (
                  // Sent
                  <div className={styles.messageSent}>
                    <p>{message.message}</p>
                    <div className={styles.messageTSSent}>
                      {message.created_time
                        .split('T')
                        .map((item) => {
                          return item.includes('-')
                            ? item.split('-').slice(1, 3).toReversed().join('-')
                            : item.split(':').slice(0, 2).join(':');
                        })
                        .toReversed()
                        .join(' ')}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
