import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Stack, Typography, Card } from '@mui/material';

// Utility to format numbers
const formatNumber = (num) => {
  if (num >= 1e6) return `${(num / 1e6).toFixed(1)}M`;
  if (num >= 1e3) return `${(num / 1e3).toFixed(1)}K`;
  return num;
};

// Utility to format date
const formatDate = (dateStr) => {
  const [year, month] = dateStr.split('-');
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
  }).format(new Date(year, month - 1));
};

const GrowthChart = ({ title, subtitle, data, xKey, yKey, color }) => {
  const chartData = {
    series: [
      {
        name: title,
        data: data.map((item) => item[yKey]),
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: data.map((item) => formatDate(item[xKey])),
        title: {
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => formatNumber(value), // Apply number formatting
        },
        title: {
          text: subtitle,
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
      },
      stroke: {
        curve: 'smooth',
        width: 4,
      },
      colors: [color],
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (value) => formatNumber(value), // Apply number formatting in tooltip
        },
      },
      grid: {
        borderColor: '#e0e0e0',
        strokeDashArray: 5,
      },
    },
  };

  return (
    <Card variant="outlined" sx={{ p: 3, width: '100%' }}>
      <Stack spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      </Stack>
    </Card>
  );
};

GrowthChart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      [PropTypes.string]: PropTypes.number,
    })
  ).isRequired,
  xKey: PropTypes.string.isRequired,
  yKey: PropTypes.string.isRequired,
  color: PropTypes.string,
};

GrowthChart.defaultProps = {
  subtitle: '',
  color: '#6C63FF',
};

export default GrowthChart;
