import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  margin: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'dark' ? '#308fe8' : '#7c087f',
  },
}));

const AudienceInterest = ({ audienceInterests }) => {
  const [brandInterest, setBrandInterest] = useState([]);
  const [maxValue, setMaxValue] = useState(1); // Initialize with 1 to prevent division by zero

  useEffect(() => {
    if (audienceInterests) {
      setBrandInterest(audienceInterests);
      // Calculate the maximum value to normalize the data
      const maxInterestValue = Math.max(...Object.values(audienceInterests));
      setMaxValue(maxInterestValue);
    }
  }, [audienceInterests]);

  return (
    <Grid item xs={6} sx={{ padding: '10px' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Audience Interests
      </Typography>

      <Stack
        direction="column"
        spacing={2}
        sx={{ padding: '10px', height: '300px', overflowY: 'scroll' }}
      >
        {Object.entries(brandInterest).map(([brand, affinityValue]) => {
          // Scale the value to fit 0 to 100 based on the maximum value in the dataset
          const scaledValue = (affinityValue / maxValue) * 100;

          return (
            <Stack direction="column" spacing={2} key={brand}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {brand}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', paddingRight: '10px' }}
                >
                  {scaledValue.toFixed(2)}% {/* Display value scaled to 100% */}
                </Typography>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                value={scaledValue} // Ensure value is directly used as percentage
                sx={{
                  marginTop: '5px',
                  margin: '10px',
                }}
              />
            </Stack>
          );
        })}
      </Stack>
    </Grid>
  );
};

export default AudienceInterest;
